
import GroupCard from '@/common/components/groupCard/GroupCard.vue';
import GroupCardHeader from '@/resources/my-companies/components/GroupCardHeader.vue';
import mixins from 'vue-typed-mixins';
import CountryGroupCardMixin from '@/resources/my-companies/components/GroupCard/CountryGroupCardMixin';

export default mixins(
  CountryGroupCardMixin,
).extend({
  name: 'ForeignGroupCard',
  components: {
    GroupCard,
    GroupCardHeader,
  },
  data() {
    return {
      headerLabels: [
        this.$t('myCompanies.groupCardTable.foreign.nationalRegister'),
        this.$t('myCompanies.groupCardTable.foreign.name'),
        this.$t('myCompanies.groupCardTable.foreign.location'),
      ],
      expandableLabels: [
        this.$t('myCompanies.groupCardTable.foreign.expandable.nationalRegister'),
        this.$t('myCompanies.groupCardTable.foreign.expandable.name'),
        this.$t('myCompanies.groupCardTable.foreign.expandable.location'),
      ],
    };
  },
  methods: {
  },
});
