var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("GroupCard", {
    attrs: {
      "header-labels": _vm.headerLabels,
      "expandable-labels": _vm.expandableLabels,
      "expandable-total": _vm.totalBranchs,
      "expandable-loading": _vm.expandableLoading,
      "table-data": _vm.group.companies,
      "expandable-paginator-label": _vm.$t(
        "myCompanies.groupCardTable.expandablePaginatorLabel"
      ),
    },
    on: {
      handleExpandableItems: _vm.handleExpandableItems,
      changePageSize: _vm.changePageSize,
      goToLastPage: _vm.goToLastPage,
      goToFirstPage: _vm.goToFirstPage,
      nextPage: _vm.nextPage,
      previousPage: _vm.previousPage,
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("GroupCardHeader", {
              attrs: { group: _vm.group },
              on: {
                registerGroup: function ($event) {
                  return _vm.$emit("registerGroup")
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function ({ item, column }) {
          return [
            column ===
            _vm.$t("myCompanies.groupCardTable.foreign.nationalRegister")
              ? _c("td", { staticClass: "data" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNationalRegister(
                          item.nationalRegister,
                          item.origin
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            column === _vm.$t("myCompanies.groupCardTable.foreign.name")
              ? _c("td", { staticClass: "data" }, [
                  _vm._v(" " + _vm._s(item.name) + " "),
                ])
              : _vm._e(),
            column === _vm.$t("myCompanies.groupCardTable.foreign.location")
              ? _c("td", { staticClass: "data" }, [
                  _vm._v(
                    " " +
                      _vm._s(item.address && item.address.state) +
                      " - " +
                      _vm._s(item.address && item.address.city) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]
        },
      },
      {
        key: "expandable",
        fn: function ({ item }) {
          return [
            _c("td", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formatNationalRegister(
                      item.nationalRegister,
                      item.origin
                    )
                  ) +
                  " "
              ),
            ]),
            _c("td", [_vm._v(" " + _vm._s(item.name) + " ")]),
            _c("td", [
              _vm._v(
                " " +
                  _vm._s(item.address && item.address.state) +
                  " - " +
                  _vm._s(item.address && item.address.city) +
                  " "
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }